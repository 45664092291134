'use client'

import Error from 'next/error'
import { GlobalError as GlobalErrorUI } from 'pattern-library/src/complexUI/GlobalError'

export default function GlobalError({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
  console.error('Global error:', error)
  return (
    <html>
      <body>
        <GlobalErrorUI />
      </body>
    </html>
  )
}
