'use client'

import Link from 'next/link'

import { Button } from '../basicUI/Button'
import { Flex } from '../basicUI/Flex'
import { Text } from '../basicUI/Text'
import { styled } from '../stitches.config'

export const GlobalError = () => {
  // Design: https://codepen.io/Kxrl/pen/BOPNXN

  return (
    <Container
      id="container"
      onMouseMove={event => {
        const elEyes = document.getElementsByClassName('eye') as HTMLCollectionOf<HTMLDivElement>
        if (elEyes.length) {
          const elEye = elEyes[0]
          const x = elEye.offsetLeft + elEye.clientWidth / 2
          const y = elEye.offsetTop + elEye.clientHeight / 2
          const rad = Math.atan2(event.pageX - x, event.pageY - y)
          const rot = rad * (180 / Math.PI) * -1 + 180
          // iterate through all eyes
          for (let i = 0; i < elEyes.length; i++) {
            const elEye = elEyes[i]
            elEye.style.transform = `rotate(${rot}deg)`
          }
        }
      }}
    >
      <NumberContainer justify="center">
        <ErrorNum>5</ErrorNum>
        <Eye className="eye" />
        <Eye className="eye" />
      </NumberContainer>
      <Text>
        Oh eyeballs! Something went wrong. We're <i>looking</i> to see what happened.
      </Text>
      <Link href="/">
        <Button label="Go Back" />
      </Link>
    </Container>
  )
}

const Container = styled('div', {
  backgroundColor: 'rgb(51, 51, 51)',
  width: '100vw',
  height: '100vh',
  color: 'white',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '24px',
  alignItems: 'center',
})

const NumberContainer = styled(Flex, {
  fontFamily: 'Arial Black',
})

const ErrorNum = styled('span', {
  fontSize: '130px',
  lineHeight: '100px',
})

const Eye = styled('div', {
  background: '#fff',
  borderRadius: '50%',
  display: 'inline-block',
  height: '100px',
  position: 'relative',
  width: '100px',
  '&::after': {
    background: '#000',
    borderRadius: '50%',
    bottom: '56.1px',
    content: '""',
    height: '33px',
    position: 'absolute',
    right: '33px',
    width: '33px',
  },
})
